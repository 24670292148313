import { browser } from "$app/environment"

import { lightDarkMode$ } from "./local"

import { Capacitor } from "@capacitor/core"
import { StatusBar, Style } from "@capacitor/status-bar"
import { Keyboard, KeyboardStyle } from "@capacitor/keyboard"

async function applyStyle(style: "light" | "dark") {
    if (style === "dark") {
        console.info("[style] apply dark")

        document.documentElement.classList.add("dark")

        if (Capacitor.isPluginAvailable("StatusBar")) {
            await StatusBar.setStyle({ style: Style.Dark })
        }
        if (Capacitor.isPluginAvailable("Keyboard")) {
            await Keyboard.setStyle({ style: KeyboardStyle.Dark })
        }
    } else if (style === "light") {
        console.info("[style] apply light")

        document.documentElement.classList.remove("dark")

        if (Capacitor.isPluginAvailable("StatusBar")) {
            await StatusBar.setStyle({ style: Style.Light })
        }
        if (Capacitor.isPluginAvailable("Keyboard")) {
            await Keyboard.setStyle({ style: KeyboardStyle.Light })
        }
    }
}

// @feat: darkmode (apply style when system setting changes)
browser &&
    window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", ({ matches }) => {
        if (lightDarkMode$.value === "system") {
            applyStyle(matches ? "dark" : "light")
        }
    })

browser &&
    lightDarkMode$.subscribe((lightDarkMode) => {
        if (lightDarkMode === null || lightDarkMode === "system") {
            const { matches } = window.matchMedia("(prefers-color-scheme: dark)")
            applyStyle(matches ? "dark" : "light")
        } else if (lightDarkMode === "light") {
            applyStyle("light")
        } else if (lightDarkMode === "dark") {
            applyStyle("dark")
        }
    })
