export const prerender = true
export const ssr = true
export const csr = true

/* ============================================================================================== */

import { browser } from "$app/environment"
import * as env from "$env/static/public"
import { setLocale } from "$i18n/i18n-svelte"
import { loadLocaleAsync } from "$i18n/i18n-util.async"
import { detectLocaleBrowser } from "$lib/settings/lang"
import posthog from "posthog-js"
import type { LayoutLoad } from "./$types"

/* ============================================================================================== */

export const load: LayoutLoad<{ locale: Locales }> = async ({ data: { locale } }) => {
    // ! PostHog
    if (browser) {
        posthog.init(env.PUBLIC_POSTHOG_API_KEY, {
            api_host: "https://eu.posthog.com",
            // because these events are reported manually in `+layout.svelte`
            capture_pageview: false,
            capture_pageleave: false,
            // to not track not-logged-in users across sessions
            persistence: "memory",
            // hide areas like chat
            session_recording: {
                maskAllInputs: false,
                maskInputOptions: {
                    email: true,
                    password: true,
                },
                maskTextSelector: "#SENSITIVE",
            },
        })
    }

    // ! Locale
    if (browser) {
        // on browser side, potentially overwrite `locale`
        // this is required when using Static Site Generation
        locale = detectLocaleBrowser()
        document.querySelector("html")!.setAttribute("lang", locale)

        // only load required in browser, because on server all locales are loaded on startup (`hooks.server.ts`)
        await loadLocaleAsync(locale)
    }
    // set language for rendering
    setLocale(locale)

    // pass locale to pages inside `$page.data`
    return { locale }
}
