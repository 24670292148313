<script lang="ts">
    import ModalVC from "$components/modal/ModalVC.svelte"

    // @feat: darkmode
    import "$lib/settings/layout"
    import "$lib/settings/style"

    import { page } from "$app/stores"
    import * as env from "$env/static/public"
    import type { WebSite } from "schema-dts"
    import { JsonLd, MetaTags, type MetaTagsProps } from "svelte-meta-tags"

    import { browser } from "$app/environment"
    import { afterNavigate, beforeNavigate } from "$app/navigation"
    import { DEBUG$ } from "$lib/settings/layout"
    import posthog from "posthog-js"

    /* ============================================================================================== */
    /*                                           Debug Mode                                           */
    /* ============================================================================================== */

    if (browser) {
        page.subscribe((page) => {
            page.url.searchParams.has("debug") ? DEBUG$.next(true) : DEBUG$.next(false)
        })
    }

    /* ========================================================================================== */

    // @feat: PostHog
    if (browser) {
        beforeNavigate(() => posthog.capture("$pageleave"))
        afterNavigate(() => posthog.capture("$pageview"))
    }

    /* ======================================== Meta Tags ======================================= */

    const defaultMetaTags: MetaTagsProps = {
        title: "GroupTube – Together with Friends",
        description:
            "Your place to meet and watch videos with friends online. Meet your friends and watch videos together on GroupTube!",
        keywords: [
            "watch",
            "youtube",
            "video",
            "together",
            "meet",
            "friends",
            "grouptube",
            "group",
            "tube",
            "gt",
            "group.tube",
            "watch2gether",
            "w2g",
            "synchronized",
            "call",
        ],
        canonical: new URL($page.url.pathname, env.PUBLIC_FRONTEND_URL).href,
        openGraph: {
            siteName: "GroupTube",
            type: "website",
            url: new URL($page.url.pathname, env.PUBLIC_FRONTEND_URL).href,
            images: [{ url: `${env.PUBLIC_FRONTEND_URL}/logo/icon-256px.png` }],
        },
    }

    $: metaTags = {
        ...defaultMetaTags,
        ...($page.data.metaTagsChild
            ? {
                  // don't add " – GroupTube" to the title if it's a blog post
                  titleTemplate: !$page.url.pathname.startsWith("/blog/") ? "%s – GroupTube" : "%s",
                  ...$page.data.metaTagsChild,
              }
            : {}),
    }

    /* ========================================================================================== */

    const websiteSchema: WebSite = {
        "@type": "WebSite",
        name: "GroupTube",
        alternateName: "GT",
        url: env.PUBLIC_FRONTEND_URL,
        // about: "Your place to meet and watch videos with friends online. Meet your friends and watch videos together on GroupTube!",
        // image: `${env.PUBLIC_FRONTEND_URL}/logo/icon-256px.png`,
    }

    $: jsonLds = $page.data.jsonLds ? [websiteSchema, ...$page.data.jsonLds] : websiteSchema

    let i = Math.random()
</script>

<MetaTags {...metaTags} />
<JsonLd schema={jsonLds} />

<!-- Has to be parent because it provides context -->
<ModalVC>
    <slot />
</ModalVC>
